import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

// app styles


import 'bootstrap-css-only/css/bootstrap.min.css'
import './assets/styles/Antd/antd.cleanui.scss'
import './assets/styles/CleanUI/cleanui.scss';
// import './assets/styles/Bootstrap/bootstrap.cleanui.scss';
// import './assets/styles/Chartist/chartist.cleanui.scss';
import './assets/styles/Nprogress/nprogress.cleanui.scss';
import './assets/styles/Rcdrawer/rcdrawer.cleanui.scss';
// import 'c3/c3.min.css';
import '@ant-design/compatible/assets/index.css';
import './assets/css/tailwind.css'
import './global.scss'
import { App } from './App'

ReactDOM.render(
  <App />,
  document.getElementById('root'),
)

serviceWorker.register()
