import actions from './actions'

const initialState = {
    lotsData: [],
    oneProduct: [],
    productData: [],
    inactiveProductData: [],
    productLotData: [],
    productBatchData: [],
    packageData: [],
    QRCSVData: [],
    onePackage: [],
    batchData: [],
    inStockPackage: [],
    pageNumber: 1,
    qrPageNumber: 1,
    pageSize: 10,
    qrPageSize: 10,
    phcBasicDetail: [],
    phcSpecificData: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_LOTS_LIST:
            return { ...state, lotsData: action.payload, error: false }

        case actions.GET_BATCH_LIST:
            return { ...state, batchData: action.payload, error: false }

        case actions.GET_PRODUCT_DATA:
            return { ...state, productData: action.payload, error: false }

        case actions.UPDATE_BATCH_DETAIL:
            return { ...state, error: false }

        case actions.GET_INACTIVE_PRODUCT_DATA:
            return { ...state, inactiveProductData: action.payload, error: false }

        case actions.SET_PRODUCT_DATA:
            return { ...state, productData: action.payload, error: false }

        case actions.GET_PRODUCT_DETAIL:
            return { ...state, oneProduct: action.payload, error: false }

        case actions.SET_PRODUCT_LOT:
            return { ...state, productLotData: action.payload, error: false }

        case actions.GET_PRODUCT_BATCH_DETAIL:
            return { ...state, productBatchData: action.payload, error: false }

        case actions.SET_PRODUCT_BATCH:
            return { ...state, productBatchData: action.payload, error: false }

        case actions.UPDATE_PRODUCT_BATCH:
            return { ...state, productBatchData: action.payload, error: false }

        case actions.SET_PACKAGE:
            return { ...state, packageData: action.payload, error: false }

        case actions.GET_PACKAGE_DATA:
            return { ...state, packageData: action.payload, error: false }

        case actions.GET_INSTOCK_PACKAGE_DATA:
            return { ...state, inStockPackage: action.payload, error: false }

        case actions.UPDATE_PACKAGE_DETAIL:
            return { ...state, onePackage: action.payload, error: false }

        case actions.GET_PACKAGE_DETAIL:
            return { ...state, onePackage: action.payload, error: false }

        case actions.GET_QRCSV_DATA:
            return { ...state, QRCSVData: action.payload, error: false }

        case actions.CHANGE_PAGE_NUMBER:
            return { ...state, pageNumber: action.payload, error: false }

        case actions.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, error: false }

        case actions.CHANGE_QR_PAGE_NUMBER:
            return { ...state, qrPageNumber: action.payload, error: false }

        case actions.CHANGE_QR_PAGE_SIZE:
            return { ...state, qrPageSize: action.payload, error: false }

        case actions.PHC_BASIC_DETAIL:
            return { ...state, phcBasicDetail: action.payload, error: false }

        case actions.PHC_SPECIFIC_DATA:
            return { ...state, phcSpecificData: action.payload, error: false }

        default:
            return state
    }
}