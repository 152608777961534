import React from 'react'
import Loadable from 'react-loadable'
import Loader from './components/LayoutComponents/Loader'
const loadable = (loader) =>
    Loadable({
        loader,
        delay: false,
        loading: () => <Loader />,
    })

export const getRoutes = (user) => {
    let routes = [
        // System Pages
        {
            path: '/user/app',
            component: loadable(() => import('./pages/user/app.jsx')),
            exact: true,
        },
        {
            path: '/user/login',
            component: loadable(() => import('./pages/user/login/loginPage')),
            exact: true,
        },
        {
            path: '/user/forgot',
            component: loadable(() => import('./pages/user/forgot')),
            exact: true,
        },
        {
            path: '/user/resetPassword',
            component: loadable(() => import('./pages/user/forgot/resetPassword.jsx')),
            exact: true,
        },
        {
            path: '/dashboard/home',
            exact: true,
            component: loadable(() => import('./pages/home')),
        },
        {
            path: '/client',
            exact: true,
            breadcrumb: ["Client"],
            component: loadable(() => import('./pages/client/index.jsx')),
        },
        {
            path: '/client/add/:type',
            exact: true,
            breadcrumb: ["Client", "Add Client"],
            component: loadable(() => import('./pages/client/addClient.jsx')),
        },
        {
            path: '/client/:id/edit',
            exact: true,
            breadcrumb: ["Client", "Edit Client"],
            component: loadable(() => import('./pages/client/editClient.jsx')),
        },
        {
            path: '/client/:id/:type',
            exact: true,
            breadcrumb: ["Client", "Detail"],
            component: loadable(() => import('./pages/client/clientDetail.jsx')),
        },
        {
            path: '/package',
            exact: true,
            breadcrumb: ["Package"],
            component: loadable(() => import('./pages/package/package.jsx')),
        },
        {
            path: '/package/detail/:id',
            exact: true,
            breadcrumb: ["Package", "Detail"],
            component: loadable(() => import('./pages/package/packageDetail.jsx')),
        },
        {
            path: '/product',
            exact: true,
            breadcrumb: ["Product"],
            component: loadable(() => import('./pages/product/productIndex.jsx')),
        },
        {
            path: '/product/add',
            exact: true,
            breadcrumb: ["Product", "Add Product"],
            component: loadable(() => import('./pages/product/addProduct.jsx')),
        },
        {
            path: '/product/add/marketplace',
            exact: true,
            breadcrumb: ["Product", "Add Product"],
            component: loadable(() => import('./pages/product/addProductMarketplace.jsx')),
        },
        {
            path: '/product/add/phc',
            exact: true,
            breadcrumb: ["Product", "Add PHC"],
            component: loadable(() => import('./pages/product/addProductBasicPHC.jsx')),
        },
        {
            path: '/product/add/advancedphc',
            exact: true,
            breadcrumb: ["Product", "Add Advanced PHC"],
            component: loadable(() => import('./pages/product/addProductAdvancedPHC.jsx')),
        },
        {
            path: '/product-category',
            exact: true,
            breadcrumb: ["Product", "Product Category"],
            component: loadable(() => import('./pages/product/productCategory.jsx')),
        },
        {
            path: '/product/add/:type',
            exact: true,
            breadcrumb: ["Product", "Add Product Detail"],
            component: loadable(() => import('./pages/product/addProductDetail.jsx')),
        },
        {
            path: '/product/detail/:category/:type/:id',
            exact: true,
            breadcrumb: ["Product", "Detail"],
            component: loadable(() => import('./pages/product/productDetail.jsx')),
        },
        {
            path: '/product/edit/:type/:id',
            exact: true,
            breadcrumb: ["Product", "Edit Product"],
            component: loadable(() => import('./pages/product/editProduct.jsx')),
        },
        {
            path: '/batch/boxData/:id',
            exact: true,
            breadcrumb: ["Product", "QR CSV"],
            component: loadable(() => import('./pages/product/boxCSV.jsx')),
        },
        {
            path: '/status',
            exact: true,
            breadcrumb: ["Test Review"],
            component: loadable(() => import('./pages/status')),
        },
        {
            path: '/crm',
            exact: true,
            breadcrumb: ["CRM Model"],
            component: loadable(() => import('./pages/crm')),
        },
        {
            path: '/docQr',
            exact: true,
            breadcrumb: ["Doc QR"],
            component: loadable(() => import('./pages/docQr')),
        },
        {
            path: '/doctor',
            exact: true,
            breadcrumb: ["Doctor"],
            component: loadable(() => import('./pages/doctor')),
        },
        {
            path: '/doctor/add',
            exact: true,
            breadcrumb: ["Doctor", "Add Doctor"],
            component: loadable(() => import('./pages/doctor/addDoctor.jsx')),
        },
        {
            path: '/doctors/edit/:id',
            exact: true,
            breadcrumb: ["Doctor", "Edit Doctor"],
            component: loadable(() => import('./pages/doctor/editDoctor.jsx')),
        },
        {
            path: '/store',
            exact: true,
            breadcrumb: ["Store"],
            component: loadable(() => import('./pages/medicalStore')),
        },
        {
            path: '/store/add',
            exact: true,
            breadcrumb: ["Store", "Add Store"],
            component: loadable(() => import('./pages/medicalStore/addStore.jsx')),
        },
        {
            path: '/store/edit/:id',
            exact: true,
            breadcrumb: ["Store", "Edit Store"],
            component: loadable(() => import('./pages/medicalStore/editStore.jsx')),
        },
        {
            path: '/crm/detail/:id',
            exact: true,
            breadcrumb: ["CRM Model", "Detail"],
            component: loadable(() => import('./pages/crm/crmDetail.jsx')),
        },
        {
            path: '/crm/appointment/detail',
            exact: true,
            breadcrumb: ["CRM Model", "Appointment Detail"],
            component: loadable(() => import('./pages/crm/appointmentDetail.jsx')),
        },
        {
            path: '/services',
            exact: true,
            breadcrumb: ["Services"],
            component: loadable(() => import('./pages/testform')),
        },
        {
            path: '/orders',
            exact: true,
            breadcrumb: ["Orders"],
            component: loadable(() => import('./pages/orders')),
        },
        {
            path: '/orders/detail/:id',
            exact: true,
            breadcrumb: ["Orders", "Detail"],
            component: loadable(() => import('./pages/orders/orderDetail.jsx')),
        },
        {
            path: '/orders/qrCode/:type/:id',
            exact: true,
            breadcrumb: ["Orders", "Detail"],
            component: loadable(() => import('./pages/orders/qrCode.jsx')),
        },
        {
            path: '/Account',
            exact: true,
            breadcrumb: ["Account"],
            component: loadable(() => import('./pages/member/account.jsx')),
        },
        {
            path: '/members',
            exact: true,
            breadcrumb: ["Members"],
            component: loadable(() => import('./pages/member/index.jsx')),
        },
        {
            path: '/users',
            exact: true,
            breadcrumb: ["User"],
            component: loadable(() => import('./pages/users/index.jsx')),
        },
        {
            path: '/users/detail/:category/:id/:type',
            exact: true,
            breadcrumb: ["Users", "Detail"],
            component: loadable(() => import('./pages/users/userDetail.jsx')),
        },
        {
            path: '/appointment/book',
            exact: true,
            breadcrumb: ["Users", "Book Appointment"],
            component: loadable(() => import('./pages/users/bookAppointment.jsx')),
        },
        {
            path: '/appointment/detail',
            exact: true,
            breadcrumb: ["Appointment", "Detail"],
            component: loadable(() => import('./pages/users/appointmentDetail.jsx')),
        },
        {
            path: '/members/edit/:id',
            exact: true,
            breadcrumb: ["Members", "Edit Member"],
            component: loadable(() => import('./pages/member/editMember.jsx')),
        },
        {
            path: '/banner',
            exact: true,
            breadcrumb: ["Banner"],
            component: loadable(() => import('./pages/member/banner.jsx')),
        },
        {
            path: '/coupon',
            exact: true,
            breadcrumb: ["Coupon"],
            component: loadable(() => import('./pages/member/coupon.jsx')),
        },
        {
            path: '/warehouse',
            exact: true,
            breadcrumb: ["Warehouse"],
            component: loadable(() => import('./pages/warehouse/index.jsx')),
        },
        {
            path: '/faq',
            exact: true,
            breadcrumb: ["FAQ"],
            component: loadable(() => import('./pages/member/faq.jsx')),
        }
        ,
        {
            path: '/user/report/:id',
            exact: true,
            breadcrumb: ["Report"],
            component: loadable(() => import('./pages/member/report.jsx')),
        },
        {
            path: '/user/angcardReport/:id',
            exact: true,
            breadcrumb: ["Report"],
            component: loadable(() => import('./pages/member/angCardReport')),
        },
        {
            path: '/franchisee',
            exact: true,
            breadcrumb: ["Franchisee"],
            component: loadable(() => import('./pages/franchisee/index.jsx')),
        },
        {
            path: '/franchisee/add',
            exact: true,
            breadcrumb: ["Add Franchisee"],
            component: loadable(() => import('./pages/franchisee/addfranchisee.jsx')),
        },
    ]
    if (user && user.user && user.user.role == window.SITE_CONFIG.USER_ROLE.SUPER_ADMIN)
        routes.push({
            path: '/members/add',
            exact: true,
            breadcrumb: ["Members", "Add Member"],
            component: loadable(() => import('./pages/member/addMember.jsx')),
        })
    return routes;
}