import React from 'react'
import { Menu, Dropdown } from 'antd'
import styles from './style.module.scss'
import { getNotificationData } from './Redux/method'
import { connect } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'


class HomeMenu extends React.Component {


  async componentDidMount() {
    //await this.props.getNotificationData()
  }


  render() {
    // const menu = (
    //   <Menu selectable={false} className={styles.activity}>
    //     {this.props.homeMenu.data.map((item, i) => (
    //       <Menu.Item className={styles.item} key={i}>
    //         <i className={`${styles.icon} icmn-star-full`} />
    //         <div className={styles.inner}>
    //           <div className={styles.title}>
    //             <span className="pull-right">{moment(item.createdAt).fromNow()}</span>
    //             <span className={classNames("text-capitalize", { "text-blue-500": item.level == "INFO", "text-red-500": item.level == "ERROR", "text-yellow-500": item.level == "WARN" })}>
    //               {(item.level == "INFO") ? "Info" : (item.level == "ERROR") ? "Important" : (item.level == "WARN") ? "Warning" : item.level}
    //             </span>
    //           </div>
    //           <div className={styles.descr + " whitespace-pre-wrap"}>
    //             {item.msg}
    //           </div>
    //         </div>
    //       </Menu.Item>
    //     ))}
    //   </Menu>
    // )
    return (
      <Dropdown trigger={['click']}>
        {/* <Dropdown overlay={menu} trigger={['click']}> */}
        <div className={styles.dropdown}>
          <i className="icmn-bell topbar__dropdownIcon" />
        </div>
      </Dropdown>
    )
  }
}

const mapStateToProps = (state) => {
  return { homeMenu: state.homeMenu }
}

export default connect(mapStateToProps, { getNotificationData })(HomeMenu)
