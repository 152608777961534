export default {
    GET_PRODUCT_DATA: "GET_PRODUCT_DATA",
    GET_INACTIVE_PRODUCT_DATA: "GET_INACTIVE_PRODUCT_DATA",
    SET_PRODUCT_DATA: "SET_PRODUCT_DATA",
    GET_PRODUCT_DETAIL: "GET_PRODUCT_DETAIL",
    UPDATE_PRODUCT_DETAIL: "UPDATE_PRODUCT_DETAIL",
    SET_PRODUCT_LOT: "SET_PRODUCT_LOT",
    GET_PRODUCT_LOT_DETAIL: "GET_PRODUCT_LOT_DETAIL",
    GET_PRODUCT_BATCH_DETAIL: "GET_PRODUCT_BATCH_DETAIL",
    SET_PRODUCT_BATCH: "SET_PRODUCT_BATCH",
    SET_PACKAGE: "SET_PACKAGE",
    GET_PACKAGE_DATA: "GET_PACKAGE_DATA",
    GET_INSTOCK_PACKAGE_DATA: "GET_INSTOCK_PACKAGE_DATA",
    GET_PACKAGE_DETAIL: "GET_PACKAGE_DETAIL",
    UPDATE_PACKAGE_DETAIL: "UPDATE_PACKAGE_DETAIL",
    GET_QRCSV_DATA: "GET_QRCSV_DATA",
    GET_LOTS_LIST: "GET_LOTS_LIST",
    GET_BATCH_LIST: "GET_BATCH_LIST",
    UPDATE_BATCH_DETAIL: "UPDATE_BATCH_DETAIL",
    UPDATE_PRODUCT_BATCH: "UPDATE_PRODUCT_BATCH",
    CHANGE_PAGE_NUMBER: 'product/CHANGE_PAGE_NUMBER',
    CHANGE_PAGE_SIZE: 'product/CHANGE_PAGE_SIZE',
    CHANGE_QR_PAGE_NUMBER: 'productQr/CHANGE_QR_PAGE_NUMBER',
    CHANGE_QR_PAGE_SIZE: 'productQr/CHANGE_QR_PAGE_SIZE',
    PHC_BASIC_DETAIL: "PHC_BASIC_DETAIL",
    PHC_SPECIFIC_DATA : "PHC_SPECIFIC_DATA"
}