import actions from './actions'

const initialState = {
    userData: [],
    memberTableData: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_USER_DATA:
            return { ...state, userData: action.payload, error: false }

        case actions.GET_MEMBER_TABLE_DATA:
            return { ...state, memberTableData: action.payload, error: false }

        default:
            return state
    }
}