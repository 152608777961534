import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import menu from './menu/reducers'
import settings from './settings/reducers'
import { LoginReducer } from '../pages/user/login/index'
//import StatsReducer from '../pages/dashboard/home/Redux/reducer'
import ForgotPassword from '../pages/user/forgot/redux/reducers'
import Client from '../pages/client/redux/reducers'
import Member from '../pages/member/redux/reducers'
import Home from '../pages/home/Redux/reducer'
import HomeMenu from '../components/LayoutComponents/TopBar/HomeMenu/Redux/reducer'
import ProductList from '../pages/product/redux/reducers'
import Package from '../pages/package/redux/reducers'
import Orders from '../pages/orders/redux/reducers'
import Forms from '../pages/testform/redux/reducers'
import Users from '../pages/users/Redux/reducer'
import TestReview from '../pages/status/redux/reducers'
import CRM from '../pages/crm/redux/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    // user,
    menu,
    settings,
    user: LoginReducer,
    //stats: StatsReducer,
    forgotPassword: ForgotPassword,
    client: Client,
    member: Member,
    home: Home,
    homeMenu: HomeMenu,
    productList: ProductList,
    packageData: Package,
    orders: Orders,
    forms: Forms,
    users: Users,
    testReview: TestReview,
    crm: CRM
  })
