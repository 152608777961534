import actions from './actions'

const initialState = {
    lotsData: [],
    packageData: [],
    onePackage: [],
    inStockPackage: [],
    pageNumber: 1,
    pageSize: 10
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_LOTS_LIST:
            return { ...state, lotsData: action.payload, error: false }

        case actions.SET_PACKAGE:
            return { ...state, packageData: action.payload, error: false }

        case actions.GET_PACKAGE_DATA:
            return { ...state, packageData: action.payload, error: false }

        case actions.GET_INSTOCK_PACKAGE_DATA:
            return { ...state, inStockPackage: action.payload, error: false }

        case actions.UPDATE_PACKAGE_DETAIL:
            return { ...state, onePackage: action.payload, error: false }

        case actions.GET_PACKAGE_DETAIL:
            return { ...state, onePackage: action.payload, error: false }

        case actions.CHANGE_PAGE_NUMBER:
            return { ...state, pageNumber: action.payload, error: false }

        case actions.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, error: false }

        default:
            return state
    }
}