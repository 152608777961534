import actions from './actions'

const initialState = {
    clients: [],
    oneClient: [],
    clientId: "",
    clientProduct: [],
    productData: [],
    productLotsData: [],
    qrCodeData: [],
    pageNumber: 1,
    pageSize: 10
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_CLIENT:
            return { ...state, clients: action.payload, error: false }

        case actions.SET_CLIENT_ID:
            return { ...state, clientId: action.payload, error: false }

        case actions.GET_CLIENT:
            return { ...state, clients: action.payload, error: false }

        case actions.GET_ONE_CLIENT:
            return { ...state, oneClient: action.payload, error: false }

        case actions.GET_CLIENT_DETAIL:
            return { ...state, oneClient: action.payload, error: false }

        case actions.DELETE_CLIENT:
            return { ...state, oneClient: action.payload, error: false }

        case actions.UPDATE_CLIENT:
            return { ...state, clients: action.payload, error: false }

        case actions.GET_CLIENT_PRODUCT_DATA:
            return { ...state, clientProduct: action.payload, error: false }

        case actions.GET_PRODUCT_DATA:
            return { ...state, productData: action.payload, error: false }

        case actions.GET_PRODUCT_LOTS_DATA:
            return { ...state, productLotsData: action.payload, error: false }

        case actions.GET_QR_CODE_DATA:
            return { ...state, qrCodeData: action.payload, error: false }

        case actions.CHANGE_PAGE_NUMBER:
            return { ...state, pageNumber: action.payload, error: false }

        case actions.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, error: false }

        default:
            return state
    }
}