export default {
    GET_PENDING_DATA: "GET_PENDING_DATA",
    GET_MY_DATA: "GET_MY_DATA",
    GET_PUSHED_DATA: "GET_PUSHED_DATA",
    GET_TEST_DATA: "GET_TEST_DATA",
    UPDATE_TEST_DATA: "UPDATE_TEST_DATA",
    ASSIGN_ID: "ASSIGN_ID",
    VERIFY_REPORT: "VERIFY_REPORT",
    GET_INITIAL_DATA: "GET_INITIAL_DATA",
    GENERATE_BOTTON: "GENERATE_BOTTON",
    DOWNLOAD_DATA: "DOWNLOAD_DATA"
}