import actions from './actions'

let initialState = {}
const getUser = () => {
    initialState = {}
    let user = localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)

    if (user) {
        // TODO:Remove below line
        user = JSON.parse(user)

        initialState = { ...user, authorized: true }
    }
    return initialState;
}

export default function reducer(state = getUser(), action) {
    switch (action.type) {
        case actions.EMP_LOGIN: {
            return { ...state, ...action.payload }

        }

        case actions.EMP_LOGOUT:
            return { ...getUser() }
        default:
            return state
    }
}
