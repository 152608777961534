import actions from './action'

const initialState = {
    data: {}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_STATS:
            return { ...state, data: action.payload, error: false }

        default:
            return state
    }
}