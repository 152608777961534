import React from 'react'
import { Layout, Button } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import styles from './style.module.scss'
import classNames from 'classnames'
@withRouter
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: true,
  }

  changeBackground = () => {
    const { backgroundNumber } = this.state
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    })
  }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })
  }

  render() {
    const { children } = this.props
    const { backgroundNumber, backgroundEnabled } = this.state

    return (
      <Layout>
        <Layout.Content>
          <div
            className={backgroundEnabled ? `${styles.layout} ${styles.light}` : `${styles.layout}`}
            style={{
              backgroundImage: backgroundEnabled
                ? `url('resources/images/login_bg.jpg')`
                : `none`,
            }}
          >
            <div className={classNames(styles.header, "mb-0", "pb-0")}>
              <div >
                <Link to="/">
                  {!backgroundEnabled && (
                    <img src="resources/images/logo.png" alt="EON" />
                  )}
                  {backgroundEnabled && (
                    <img
                      src="resources/images/login_logo.png"
                      alt="EON"
                      className={"h-48"}
                    />
                  )}
                </Link>
              </div>

            </div>
            <div className={classNames(styles.content, "absolute min-h-screen justify-around min-w-full flex items-center ")}>
              <div className={" w-full lg:w-5/12  hidden  lg:block"}>
                {/** <img src="resources/images/login_im2.png" alt="onest" className="m-auto max-w-full max-h-full" />**/}
              </div>
              <div className={"w-full lg:w-6/12  flex justify-center items-center  "}>
                {children}
              </div>
            </div>
            <div className={`${styles.footer} text-center`}>
              {/* <p>&copy; Powered by Peregrine Ventures </p> */}
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
