import actionType from './actions'
import axios from '../../../../utils/axios'
import { Button, notification } from 'antd';
import { history } from "../../../../App"
import { dataURLtoFile } from "../../../../utils/helpers"
import moment from 'moment';
import { withRouter, Redirect } from 'react-router-dom'
import crypto from 'crypto';
import { Buffer } from 'buffer';
import rawFile from '../../../../public.txt'

export const loginData = (data) => async (dispatch, getState) => {
    let publicKey;
    await fetch(rawFile)
        .then(r => r.text())
        .then(text => {
            publicKey = text;
        });
    const encryptedData = crypto.publicEncrypt(
        {
            key: publicKey,
            // padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
            // oaepHash: "sha256",
        },
        // We convert the data string to a buffer using `Buffer.from`
        Buffer.from(data.password)
    )
    let password = encryptedData.toString("base64")
    let loginData = {}
    loginData.email = data.email
    loginData.password = password
    await axios.post(`eon_members/login`, loginData)
        .then(response => {
            const res = response.data.response.data;
            dispatch(dispatchUser(res))
            // const token = res.token;

            // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(res))
            // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, token)

            // dispatch({ type: actionType.EMP_LOGIN, payload: { ...res, authorized: true } })
            // dispatch({ type: "menu/GET_DATA" })
            // setTimeout(function () {
            //     notification.warning({
            //         message: 'Session is about to get expired. Please login again!'
            //     });
            // }, 3594000);
            // setTimeout(function () {
            //     localStorage.removeItem(window.SITE_CONFIG.STORAGE.USER)
            //     localStorage.removeItem('token')
            //     window.location.reload(false)
            //     history.push('/user/login')
            // }, 3600000);

        })
        .catch(err => {

            const options = {
                message: 'Login failed',
                description: err.message
            };
            if (err.response)
                options.description = err.response.data.error[0].msg
            notification.error(options);


        })

}

export const dispatchUser = (userData) => (dispatch) => {
    if (userData.token) {
        let data = [];
        let upDate = moment().add(12, 'hours');
        data.push(upDate);
        window.localStorage.setItem(window.SITE_CONFIG.STORAGE.ADMIN_EXPIRY_DATE, JSON.stringify(data))

        window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(userData))
        window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, userData.token)

        dispatch({ type: actionType.EMP_LOGIN, payload: { ...userData, authorized: true } })
        dispatch({ type: "menu/GET_DATA" })
    } else
        dispatch({ type: actionType.EMP_LOGIN, payload: { authorized: false } })
}


export const verifyEmail = (token) => async (dispatch) => {
    axios.get(`/organisation/verifyEmail?token=` + token)
        .then(response => {

            notification.success({
                message: 'Email verified successfully',
                description: "You can login now."
            });

        })
        .catch(err => {

            const options = {
                message: 'Email verification fail',
                description: err.message
            };
            if (err.response)
                options.description = err.response.data.error[0].msg
            notification.error(options);
        })
}




export const getByToken = (token, nextPage) => async (dispatch) => {
    axios.get(`/employee/getByToken`, { headers: { Authorization: "bearer " + token } })
        .then(response => {
            const res = response.data.response.data;

            dispatch(dispatchUser(res))

            // const token = res.token;

            // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(res))
            // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, token)

            // dispatch({ type: actionType.EMP_LOGIN, payload: { ...res, authorized: true } })
            // dispatch({ type: "menu/GET_DATA" })
            if (nextPage)
                history.push(nextPage)
        })
        .catch(err => {



            const options = {
                message: 'Login failed',
                description: err.message
            };
            if (err.response)
                options.description = err.response.data.error[0].msg
            notification.error(options);
        })
}

export const checkLoginData = () => (dispatch) => {
    var values = JSON.parse(localStorage.getItem(window.SITE_CONFIG.STORAGE.ADMIN_EXPIRY_DATE));

    //check "my hour" index here
    if (values[1] < moment()) {
        localStorage.removeItem(window.SITE_CONFIG.STORAGE.USER)
        //return <Redirect to="/user/login" />
    }
    let user = localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)
    if (user) {
        dispatch({ type: actionType.EMP_LOGIN, payload: { ...JSON.parse(user), authorized: true } })
    }
}


export const updateEmployeeData = (data, id, nextPage) => async (dispatch, getState) => {

    let employeeState = [...getState().employee.employees];

    // const id = data._id
    let formData = new FormData();
    formData.append('empNo', data.empNo);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    {
        data.middleName != '' ? (
            formData.append('middleName', data.middleName)
        ) : ('')
    }
    formData.append('dob', data.dob);
    formData.append('email', data.email);
    data.images.forEach((f, i) => {

        if (f.name)
            formData.append('images', f, f.name);
        else if (f.includes("data:"))
            formData.append('images', dataURLtoFile(f, `image${i}.jpg`));
        else
            formData.append('images', f);
    })

    formData.append('mobileNo', data.mobileNo);
    formData.append('gender', data.gender);
    // {data.status != undefined ? (
    //     formData.append('status', data.status)
    // ):('')} 
    formData.append('department', data.department);
    formData.append('role', data.role);
    formData.append('webRole', data.webRole);

    var response = await axios.patch(`/employee/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    const index = employeeState.findIndex(item => id === item._id);
    const item = employeeState[index];
    employeeState.splice(index, 1, {
        ...item,
        ...response.data.response.data,
    });
    if (response.status == 200) {
        window.localStorage.removeItem(window.SITE_CONFIG.STORAGE.USER)
        window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(response.data.response.data))
        dispatch({ type: "EMPLOYEE_UPDATE_DATA", payload: employeeState })
        notification.success({ message: "Update Sucessfully", description: "Profile data updated succesfully" })
        if (nextPage)
            history.push(nextPage)
    }


}