import React, { Component } from 'react'

import qs from 'qs'
import { Input, Button, Checkbox, Card, Form } from 'antd';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginData, verifyEmail } from './redux/method'
import styles from './style.module.scss'

// @connect(({ user }) => ({ user }))

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: "",
        password: "",
      },
    };
  }


  handleChangeInput = (e) => {
    let fields = this.state.fields
    fields[e.target.id] = e.target.value;
    this.setState({
      fields
    })
  }

  componentDidMount() {
    var query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (query && query.token) {
      this.props.verifyEmail(query.token)
    }
  }

  render() {


    return (
      <div className="w-1/2 lg:w-2/3 xl:w-1/2 ">
        <Helmet title="Login" />
        <Card id='card-admin-login-page' title={<div className="text-center">Login</div>}>
          <Form id='form-admin-login' layout="vertical" hideRequiredMark onFinish={this.props.loginData}>
            <Form.Item label="Email" name="email" rules={[{
              type: "email", message: 'Please enter valid email',
            }, {
              required: true, whitespace: true, message: 'Please input Email!',
            }]}>

              <Input id='form-admin-login-email' placeholder="Please input your e-mail address" required value={this.state.fields.email} id="email" allowClear onChange={this.handleChangeInput} />

            </Form.Item>
            <Form.Item label="Password" name="password" rules={[{
              required: true, message: 'Please input Password!',
            }]}>
              <Input.Password id='form-admin-password' placeholder="Enter password " required value={this.state.fields.password} id="password" allowClear onChange={this.handleChangeInput} />

            </Form.Item>

            <div className="form-actions flex items-center justify-between">
              <Button
                id='form-admin-login-button'
                type="primary"
                className="width-150 mr-4"
                htmlType="submit"
              >
                Login
                      </Button>
              <Link
                id='form-admin-login-forgot-password-link'
                to="/user/forgot"
                className="utils__link--blue  pull-right"
              >
                Forgot password?
                      </Link>
            </div>
          </Form>
        </Card>

      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return { login: state.user }
}

export default connect(mapStateToProps, { loginData, verifyEmail })(Login)

