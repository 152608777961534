import actions from './actions'
import { getLeftMenuData, getTopMenuData } from '../../services/menu'
const initialState = {
  menuLeftData: [],
  menuTopData: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.GET_DATA:
      return { ...state, ["menuLeftData"]: getLeftMenuData() }
    default:
      return state
  }
}
