import actions from './actions'

let initialState = { loading: false }

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.FORGOT_PASSWORD:
            return { ...action.payload }

        default:
            return state
    }
}
