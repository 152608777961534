import actions from './actions'

const initialState = {
    orderData: [],
    orderDetail: [],
    qrCode: [],
    pageNumber: 1,
    pageSize: 10
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.CREATE_ORDER:
            return { ...state, orderData: action.payload, error: false }

        case actions.GET_ORDER:
            return { ...state, orderData: action.payload, error: false }

        case actions.GET_ORDER_DETAIL:
            return { ...state, orderDetail: action.payload, error: false }

        case actions.GET_ORDER_QRCODE:
            return { ...state, qrCode: action.payload, error: false }

        case actions.CHANGE_PAGE_NUMBER:
            return { ...state, pageNumber: action.payload, error: false }

        case actions.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, error: false }

        default:
            return state
    }
}