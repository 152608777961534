import actions from './actions'

const initialState = {
    members: [],
    oneMember: [],
    pageNumber: 1,
    pageSize: 10
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_MEMBER:
            return { ...state, members: action.payload, error: false }

        case actions.GET_MEMBER:
            return { ...state, members: action.payload, error: false }

        case actions.GET_ONE_MEMBER:
            return { ...state, oneMember: action.payload, error: false }

        case actions.UPDATE_MEMBER:
            return { ...state, members: action.payload, error: false }

        case actions.CHANGE_PAGE_NUMBER:
            return { ...state, pageNumber: action.payload, error: false }

        case actions.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, error: false }

        default:
            return state
    }
}