import actions from './actions'

const initialState = {
    pendingData: [],
    myData: [],
    pushedData: [],
    testData: {},
    initialData : [],
    buttonData : [],
    downloadData : []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_PENDING_DATA:
            return { ...state, pendingData: action.payload, error: false }

        case actions.GET_MY_DATA:
            return { ...state, myData: action.payload, error: false }

        case actions.GET_PUSHED_DATA:
            return { ...state, pushedData: action.payload, error: false }

        case actions.GET_TEST_DATA:
            return { ...state, testData: action.payload, error: false }
        
        case actions.GET_INITIAL_DATA:
            return { ...state, initialData: action.payload, error: false }

        case actions.GENERATE_BOTTON:
            return { ...state, buttonData: action.payload, error: false }

        case actions.DOWNLOAD_DATA:
            return { ...state, downloadData: action.payload, error: false }

        default:
            return state
    }
}