/**
 * List all antd icons you want to use in your source code
 */
export {
    default as CheckCircleOutlined
} from '@ant-design/icons/CheckCircleOutlined';

export {
    default as PrinterOutlined
} from '@ant-design/icons/PrinterOutlined';

export {
    default as SearchOutlined
} from '@ant-design/icons/SearchOutlined';

export {
    default as InfoCircleTwoTone
} from '@ant-design/icons/InfoCircleTwoTone';

export {
    default as UserOutlined
} from '@ant-design/icons/UserOutlined';



export {
    default as HomeOutlined
} from '@ant-design/icons/HomeOutlined';

export {
    default as SubnodeOutlined
} from '@ant-design/icons/SubnodeOutlined';

export {
    default as ClockCircleOutlined
} from '@ant-design/icons/ClockCircleOutlined';




export {
    default as PhoneOutlined
} from '@ant-design/icons/PhoneOutlined';

export {
    default as EditOutlined
} from '@ant-design/icons/EditOutlined';

export {
    default as PlusOutlined
} from '@ant-design/icons/PlusOutlined';

export {
    default as DownloadOutlined
} from '@ant-design/icons/DownloadOutlined';

export {
    default as CalendarOutlined
} from '@ant-design/icons/CalendarOutlined';

export {
    default as UploadOutlined
} from '@ant-design/icons/UploadOutlined';


export {
    default as CompressOutlined
} from '@ant-design/icons/CompressOutlined';


export {
    default as ExpandOutlined
} from '@ant-design/icons/ExpandOutlined';


export {
    default as ReloadOutlined
} from '@ant-design/icons/ReloadOutlined';

export {
    default as DeploymentUnitOutlined
} from '@ant-design/icons/DeploymentUnitOutlined';

export {
    default as ProfileOutlined
} from '@ant-design/icons/ProfileOutlined';

export {
    default as SnippetsOutlined
} from '@ant-design/icons/SnippetsOutlined';

export {
    default as LeftOutlined
} from '@ant-design/icons/LeftOutlined';

export {
    default as RightOutlined
} from '@ant-design/icons/RightOutlined';

export {
    default as CheckOutlined
} from '@ant-design/icons/CheckOutlined';
export {
    default as CloseOutlined
} from '@ant-design/icons/CloseOutlined';

export {
    default as PieChartOutlined
} from '@ant-design/icons/PieChartOutlined';


export {
    default as QrcodeOutlined
} from '@ant-design/icons/QrcodeOutlined';


export {
    default as FolderFilled
} from '@ant-design/icons/FolderFilled';



export {
    default as InboxOutlined
} from '@ant-design/icons/InboxOutlined';


export {
    default as CloseCircleFilled
} from '@ant-design/icons/CloseCircleFilled';

export {
    default as ExclamationCircleFilled
} from '@ant-design/icons/ExclamationCircleFilled';

export {
    createFromIconfontCN
} from '@ant-design/icons/lib/components/Icon';


export {
    default as LoadingOutlined
} from '@ant-design/icons/LoadingOutlined';
export {
    default as DeleteTwoTone
} from '@ant-design/icons/DeleteTwoTone';


export {
    default as DeleteOutlined
} from '@ant-design/icons/DeleteOutlined';

import AntdIcon from '@ant-design/icons';
export default AntdIcon;


export {
    default as CheckCircleFilled
} from '@ant-design/icons/CheckCircleFilled';



export {
    getTwoToneColor
} from '@ant-design/icons/lib/components/Icon';


export {
    setTwoToneColor
} from '@ant-design/icons/lib/components/Icon';

