import React from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import HomeMenu from './HomeMenu'
import ProfileMenu from './ProfileMenu'
import { connect } from 'react-redux'
import styles from './style.module.scss'
@connect(({ user }) => ({ user }))
class TopBar extends React.Component {
  render() {
    const { user } = this.props
    return (
      <div className={styles.topbar}>

        <div>

        </div>
        <div className={"font-semibold text-primary capitalize text-xl"}>Welcome {user.user.firstName}</div>
        {/* 
        <div className="mr-4">
          <HomeMenu />
        </div> */}
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
