import actions from './actions'

const initialState = {
    forms: [],
    allForms: [],
    productCategory: [],
    testPageNumber: 1,
    testPageSize: 10,
    packagePageNumber: 1,
    packagePageSize: 10,
    oneLabTest: [],
    testPackage: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_TEST_FORMS:
            return { ...state, forms: action.payload, error: false }

        case actions.GET_ALL_TEST_FORMS:
            return { ...state, allForms: action.payload, error: false }

        case actions.SAVE_TEST_FORMS:
            return { ...state, forms: action.payload, error: false }

        case actions.CHANGE_TEST_PAGE_NUMBER:
            return { ...state, testPageNumber: action.payload, error: false }

        case actions.CHANGE_TEST_PAGE_SIZE:
            return { ...state, testPageSize: action.payload, error: false }

        case actions.CHANGE_PACKAGE_PAGE_NUMBER:
            return { ...state, packagePageNumber: action.payload, error: false }

        case actions.CHANGE_PACKAGE_PAGE_SIZE:
            return { ...state, packagePageSize: action.payload, error: false }

        case actions.GET_ONE_LAB_TEST:
            return { ...state, oneLabTest: action.payload, error: false }

        case actions.GET_TEST_PACKAGE:
            return { ...state, testPackage: action.payload, error: false }

        case actions.GET_PRODUCT_CATEGORY:
            return { ...state, productCategory: action.payload, error: false }

        default:
            return state
    }
}