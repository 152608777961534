export function getLeftMenuData() {
  let user = localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)
  if (user) {
    user = JSON.parse(user)
    if (user.user.role === window.SITE_CONFIG.USER_ROLE.ADMIN) {
      let adminMenu = [
        {
          title: 'Dashboard',
          key: 'home',
          url: '/dashboard/home',
          icon: 'icmn icmn-home',
        },
        {
          title: 'Clients',
          key: 'client',
          url: '/client',
          icon: 'icmn icmn-user',
        },
        {
          title: 'Products',
          key: 'product',
          url: '/product',
          icon: 'icmn icmn-database',
        },
        {
          title: 'Orders',
          key: 'orders',
          url: '/orders',
          icon: 'icmn icmn-file-text',
        },
        {
          title: 'Package',
          key: 'package',
          url: '/package',
          icon: 'icmn icmn-airplane',
        },
        {
          title: 'Test Review',
          key: 'status',
          url: '/status',
          icon: 'icmn icmn-bookmark',
        },
        {
          title: 'Services',
          key: 'services',
          url: '/services',
          icon: 'icmn icmn-folder',
        },
      ]

      return adminMenu
    }
    else if (user.user.role == window.SITE_CONFIG.USER_ROLE.SUPPORT) {
      let adminMenu = [
        {
          title: 'Test Review',
          key: 'status',
          url: '/status',
          icon: 'icmn icmn-bookmark',
        },
      ]

      return adminMenu
    }
    else if (user.user.role == window.SITE_CONFIG.USER_ROLE.EON_CRM) {
      let adminMenu = [
        {
          title: 'CRM',
          key: 'crm',
          url: '/crm',
          icon: 'icmn icmn-user-tie',
        },
      ]

      return adminMenu
    }
    else {

      let adminMenu = [
        {
          title: 'Dashboard',
          key: 'home',
          url: '/dashboard/home',
          icon: 'icmn icmn-home',
        },
        {
          title: 'Clients',
          key: 'client',
          url: '/client',
          icon: 'icmn icmn-user',
        },
        {
          title: 'Franchisee',
          key: 'franchisee',
          url: '/franchisee',
          icon: 'icmn icmn-user',
        },
        {
          title: 'Products',
          key: 'product',
          url: '/product',
          icon: 'icmn icmn-database',
          children: [
            {
              key: 'products',
              title: 'Product List',
              url: '/product'
            },
            {
              key: 'productcategory',
              title: 'Product Category',
              url: '/product-category'
            }
          ]
        },
        {
          title: 'Package',
          key: 'package',
          url: '/package',
          icon: 'icmn icmn-airplane',
        },
        {
          title: 'Orders',
          key: 'orders',
          url: '/orders',
          icon: 'icmn icmn-file-text',
        },
        {
          title: 'Test Review',
          key: 'status',
          url: '/status',
          icon: 'icmn icmn-bookmark',
        },
        {
          title: 'Services',
          key: 'services',
          url: '/services',
          icon: 'icmn icmn-folder',
        },
        {
          title: 'Users',
          key: 'users',
          url: '/users',
          icon: 'icmn icmn-users',
        },
        {
          title: 'CRM',
          key: 'crm',
          url: '/crm',
          icon: 'icmn icmn-user-tie',
        },
        {
          title: 'Settings',
          key: 'settings',
          url: '/settings',
          icon: 'icmn icmn-cog',
          children: [
            {
              key: 'members',
              title: 'Members',
              url: '/members',
            },
            {
              key: 'doctor',
              title: 'Doctor',
              url: '/doctor',
            },
            {
              key: 'docQr',
              title: 'Doc QR',
              url: '/docQr',
            },
            {
              key: 'medicalStore',
              title: 'Medical Store',
              url: '/store',
            },
            {
              key: 'warehouse',
              title: 'Warehouse',
              url: '/warehouse'
            },
            {
              key: 'coupon',
              title: 'Coupon',
              url: '/coupon'
            },
            {
              key: 'banner',
              title: 'Banner',
              url: '/banner'
            },
            {
              key: 'faq',
              title: 'FAQ',
              url: '/faq'
            },
            {
              key: 'account',
              title: 'Account',
              url: '/account'
            }
          ]
        }
      ]

      return adminMenu
    }
  } else {
    let adminMenu = [
      {
        title: 'Dashboard',
        key: 'home',
        url: '/dashboard/home',
        icon: 'icmn icmn-home',
      },
      {
        title: 'Clients',
        key: 'client',
        url: '/client',
        icon: 'icmn icmn-user',
      },
      {
        title: 'Products',
        key: 'product',
        url: '/product',
        icon: 'icmn icmn-database',
        children: [
          {
            key: 'products',
            title: 'Product List',
            url: '/product'
          },
          {
            key: 'productcategory',
            title: 'Product Category',
            url: '/product-category'
          }
        ]
      },
      {
        title: 'Orders',
        key: 'orders',
        url: '/orders',
        icon: 'icmn icmn-file-text',
      },
      {
        title: 'Package',
        key: 'package',
        url: '/package',
        icon: 'icmn icmn-airplane',
      },
      {
        title: 'Test Review',
        key: 'status',
        url: '/status',
        icon: 'icmn icmn-bookmark',
      },
      {
        title: 'Services',
        key: 'services',
        url: '/services',
        icon: 'icmn icmn-folder',
      },
      // {
      //   title: 'Patients',
      //   key: 'patients',
      //   url: '/patients',
      //   icon: 'icmn icmn-home',
      // },
      {
        title: 'Settings',
        key: 'settings',
        url: '/settings',
        icon: 'icmn icmn-cog',
        children: [
          {
            key: 'members',
            title: 'Members',
            url: '/members',
          },
          {
            key: 'doctor',
            title: 'Doctor',
            url: '/doctor',
          },
          {
            key: 'docQr',
            title: 'Doc QR',
            url: '/docQr',
          },
          {
            key: 'medicalStore',
            title: 'Medical Store',
            url: '/store',
          },
          {
            key: 'warehouse',
            title: 'Warehouse',
            url: '/warehouse'
          },
          {
            key: 'coupon',
            title: 'Coupon',
            url: '/coupon'
          },
          {
            key: 'banner',
            title: 'Banner',
            url: '/banner'
          },
          {
            key: 'faq',
            title: 'FAQ',
            url: '/faq'
          }
        ]
      }
    ]

    return adminMenu
  }


}
