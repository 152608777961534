import actions from './action'

const initialState = {
    data: [],
    pageNumber: 1,
    pageSize: 10,
    oneAppointment: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_STATS:
            return {
                ...state, data: action.payload,
                error: false
            }

        case actions.UPDATE_APPOINTMENT:
            return { ...state, oneAppointment: action.payload, error: false }

        default:
            return state
    }
}