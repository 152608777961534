export default {
    GET_TEST_FORMS: "GET_TEST_FORMS",
    SAVE_TEST_FORMS: 'SAVE_TEST_FORMS',
    CHANGE_TEST_PAGE_NUMBER: 'form/CHANGE_TEST_PAGE_NUMBER',
    CHANGE_TEST_PAGE_SIZE: 'form/CHANGE_TEST_PAGE_SIZE',
    CHANGE_PACKAGE_PAGE_NUMBER: 'form/CHANGE_PACKAGE_PAGE_NUMBER',
    CHANGE_PACKAGE_PAGE_SIZE: 'form/CHANGE_PACKAGE_PAGE_SIZE',
    GET_ONE_LAB_TEST: 'GET_ONE_LAB_TEST',
    GET_TEST_PACKAGE: 'GET_TEST_PACKAGE',
    GET_PRODUCT_CATEGORY: 'GET_PRODUCT_CATEGORY',
    GET_ALL_TEST_FORMS: 'GET_ALL_TEST_FORMS'


}