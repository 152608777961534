export default {
    SET_CLIENT: "SET_CLIENT",
    GET_CLIENT: "GET_CLIENT",
    UPDATE_CLIENT: "UPDATE_CLIENT",
    DELETE_CLIENT: "DELETE_CLIENT",
    GET_ONE_CLIENT: "GET_ONE_CLIENT",
    SET_CLIENT_ID: "SET_CLIENT_ID",
    GET_CLIENT_PRODUCT_DATA: "GET_CLIENT_PRODUCT_DATA",
    GET_PRODUCT_DATA: "GET_PRODUCT_DATA",
    GET_PRODUCT_LOTS_DATA: "GET_PRODUCT_LOTS_DATA",
    GET_QR_CODE_DATA: "GET_QR_CODE_DATA",
    CHANGE_PAGE_NUMBER: 'client/CHANGE_PAGE_NUMBER',
    CHANGE_PAGE_SIZE: 'client/CHANGE_PAGE_SIZE',
    GET_CLIENT_DETAIL: "GET_CLIENT_DETAIL"
}